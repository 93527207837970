import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text, Icon, Strong, Span, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Formspree, SocialMedia } from "@quarkly/components";
import { GiWhistle, GiPodiumWinner, GiTakeMyMoney, GiReceiveMoney } from "react-icons/gi";
import { FaHandHoldingHeart } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				The Fixit Queen | Real Estate Coaching, Funding, & Business Solutions
			</title>
			<meta name={"description"} content={"Dive into real estate coaching, funding strategies, business solutions, and non-profit collaborations with Debbie - The Fixit Queen."} />
			<meta property={"og:title"} content={"The Fixit Queen | Real Estate Coaching, Funding, & Business Solutions"} />
			<meta property={"og:description"} content={"Dive into real estate coaching, funding strategies, business solutions, and non-profit collaborations with Debbie - The Fixit Queen."} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/favicon%2032x32.png?v=2021-09-22T11:33:41.498Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/152x152.png?v=2021-09-22T11:33:48.679Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/152x152.png?v=2021-09-22T11:33:48.679Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/152x152.png?v=2021-09-22T11:33:48.679Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/152x152.png?v=2021-09-22T11:33:48.679Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/270x270.png?v=2021-09-22T11:33:55.934Z"} />
		</Helmet>
		<Section padding="38px 0 38px 0" quarkly-title="Header" position="static" margin="0 0 -229px 0">
			<Override slot="SectionContent" flex-direction="row" display="block" position="static" />
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				flex-direction="column-reverse"
				align-items="center"
				align-self="flex-end"
				justify-items="center"
			>
				<Image
					src="https://uploads.quarkly.io/64c325c38bd7e600185bd97f/images/logo.png?v=2023-07-31T13:25:30.409Z"
					display="grid"
					height="150px"
					text-align="left"
					lg-display="none"
				/>
				<Image
					src="https://uploads.quarkly.io/64c325c38bd7e600185bd97f/images/mark.png?v=2023-07-31T13:25:40.968Z"
					display="none"
					height="100px"
					text-align="left"
					lg-display="grid"
				/>
			</Box>
		</Section>
		<Section
			background="linear-gradient(0deg,--color-dark 0%,rgba(4, 8, 12, 0) 100%),url(https://images.unsplash.com/photo-1562762583-9e1705bcb3ff?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="200px 0 88px 0"
			quarkly-title="Hero-21"
			padding="188px 0 88px 0"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				flex-wrap="wrap"
				align-items="center"
				justify-content="center"
				display="flex"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				align-self="flex-start"
				margin="30px 0px 0px 0px"
				display="block"
			>
				<Text
					letter-spacing="2px"
					margin="0px 0px 0px 0px"
					color="--greyD2"
					font="--base"
					lg-width="100%"
					lg-text-align="left"
					text-align="center"
					text-transform="uppercase"
					background="--color-lightD1"
					padding="30px 40px 15px 15px"
					display="grid"
				>
					Real Estate Investor & Developer, Mentor, and Grant Writer
				</Text>
				<Text
					color="--dark"
					font="normal normal 500 70px/1.2 --fontFamily-googleAbrilFatface"
					sm-font="normal 700 42px/1.2 --fontFamily-googleAbrilFatface"
					md-font="normal 700 42px/1.2 --fontFamily-googleAbrilFatface"
					margin="0px 0px 0 0px"
					lg-width="100%"
					md-width="100%"
					lg-text-align="left"
					lg-margin="0px 0px 0 0px"
					lg-font="normal 700 42px/1.2 --fontFamily-googleAbrilFatface"
					text-align="left"
					letter-spacing="7px"
					border-color="--color-light"
					background="--color-lightD1"
					padding="10px 15px 15px 15px"
					display="inline-block"
				>
					The Fixit Queen
				</Text>
				<Box min-width="100px" min-height="100px">
					<Text
						lg-text-align="left"
						text-align="left"
						color="--greyD2"
						font="--lead"
						lg-width="100%"
						background="--color-lightD1"
						padding="15px 20px 15px 15px"
						display="inline-block"
						margin="0px 0px 0 0px"
						border-color="rgba(88, 109, 142, 0)"
					>
						Strategically Positioning You For Successful Outcomes
					</Text>
					<Box min-width="100px" min-height="100px">
						<Box display="flex" sm-flex-direction="column">
							<Link
								text-decoration-line="initial"
								sm-text-align="center"
								border-width="2px"
								sm-margin="0px 0px 0px 0px"
								background="--color-lightD2"
								color="--darkL2"
								transition="background-color 0.3s linear 0s"
								hover-color="--light"
								href="#contact-us"
								padding="10px 23px 10px 23px"
								hover-background="--color-dark"
								font="normal 400 28px/1.5 --fontFamily-googleFiraSans"
								margin="0px 0px 0px 0px"
								sm-width="100%"
								border-color="--color-darkL2"
							>
								Let's Work Together
							</Link>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="-180px 0px 0px 0px"
				background="--color-lightD2"
				padding="60px 60px 60px 60px"
				position="relative"
				lg-display="grid"
				border-color="transparent"
				border-style="solid"
				border-bottom-color="--color-darkL1"
				border-radius="10px"
				border-width="5px"
				lg-padding="30px 30px 30px 30px"
			>
				<Box display="block" flex-direction="column" justify-content="space-between" md-width="100%">
					<Text margin="0px 0px 20px 0px" color="--darkL2" font="--headline2" md-margin="0px 0px 30px 0px">
						Real Estate Investor & Developer, Mentor, and Grant Writer
					</Text>
					<Text
						margin="0px 0px 40px 0px"
						color="--dark"
						font="--base"
						position="relative"
						z-index="2"
						text-align="left"
					>
						Known as “The Fixit Queen”, is a real estate developer, mentor, and expert grant writer. Her skill set is as versatile as it is effective, helping clients to solve problems and achieve their dreams. Whether you’re in need of real estate coaching, creative funding strategies, business solutions to increase revenue, or a guide to launch and grow a non-profit business, “The Fixit Queen” is the right expert for you!
					</Text>
					<Image src="https://uploads.quarkly.io/64c325c38bd7e600185bd97f/images/Debbie%20Medina%205%20LR%20BLACK.png?v=2023-08-02T18:04:32.686Z" display="block" max-width="80%" />
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0px 0px 0px 0px"
					lg-padding="0px 30px 0px 0px"
					md-padding="0px 0px 0px 0px"
					md-margin="0px 0px 30px 0px"
					align-items="center"
					margin="0px 0px 0px 60px"
				>
					<Image
						max-width="450px"
						src="https://uploads.quarkly.io/64c325c38bd7e600185bd97f/images/debbie-circle-nobg.png?v=2023-07-31T02:50:42.556Z"
						md-margin="0px 0px 20px 0px"
						display="inline-block"
						lg-max-width="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="88px 0 88px 0"
			sm-padding="60px 0 60px 0"
			box-shadow="--m"
			background=",--color-light linear-gradient(0deg,rgba(0,0,0,0) 44.8%,#99154E 44.8%)"
			md-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-25"
			font="18px --fontFamily-googleDmSerifDisplay"
		>
			<Override slot="SectionContent" max-width="1220px" sm-min-width="280px" />
			<Box
				width="100%"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				margin="0px 0px 64px 0px"
				display="flex"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="400 50px/1.2 --fontFamily-serifGaramond"
					color="--lightD2"
					md-text-align="center"
					sm-font="normal 700 28px/1.2 --fontFamily-sans"
					text-align="center"
					md-font="--headline3"
				>
					Let's Turn Your Vision Into Reality Together
				</Text>
				<Text
					color="--lightD2"
					text-align="center"
					lg-width="100%"
					margin="0px 0px 0px 0px"
					font="--base"
					width="70%"
					lg-max-width="720px"
				>
					Partner with Debbie - The Fixit Queen, for strategic coaching and expert guidance in real estate, funding, business growth, and non-profit creation.
				</Text>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="24px"
				lg-grid-gap="16px"
				md-grid-template-columns="1fr"
			>
				<Box
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 46px 47px 46px"
					background="#FFFFFF"
					display="flex"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Icon
						icon={GiWhistle}
						margin="0px 0px 30px 0px"
						color="--darkL1"
						size="64px"
						category="gi"
						lg-font="48px sans-serif"
					/>
					<Text color="--darkL2" font="--lead" margin="0px 0px 18px 0px" text-align="center">
						<Span letter-spacing="0.5px" text-transform="uppercase">
							<Strong color="--darkL2">
								Real Estate Coaching
							</Strong>
						</Span>
					</Text>
					<Text
						font="--base"
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--dark"
						text-align="center"
					>
						Gain the insights and strategies of an experienced real estate developer. Learn how to navigate and succeed in the world of real estate investment.
					</Text>
				</Box>
				<Box
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 46px 47px 46px"
					background="#FFFFFF"
					display="flex"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Icon
						icon={GiPodiumWinner}
						margin="0px 0px 30px 0px"
						color="--darkL1"
						size="64px"
						category="gi"
						lg-font="48px sans-serif"
					/>
					<Text color="--darkL2" font="--lead" margin="0px 0px 18px 0px" text-align="center">
						<Strong
							letter-spacing="0.5px"
							text-transform="uppercase"
							text-align="center"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							color="--darkL2"
						>
							Business Solutions
						</Strong>
					</Text>
					<Text
						font="--base"
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--dark"
						text-align="center"
					>
						Increase your revenue through strategic business solutions. We provide personalized advice and tactics to boost your bottom line.
					</Text>
				</Box>
				<Box
					display="flex"
					background="#FFFFFF"
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 46px 47px 46px"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Icon
						size="64px"
						category="fa"
						icon={FaHandHoldingHeart}
						margin="0px 0px 30px 0px"
						color="--darkL1"
						lg-font="48px sans-serif"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--lead" text-align="center">
						<Span letter-spacing="0.5px" text-transform="uppercase">
							<Strong color="--darkL2">
								Non-Profit Collaborations
							</Strong>
						</Span>
					</Text>
					<Text
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--dark"
						font="--base"
						text-align="center"
					>
						Ready to make a positive impact? We'll show you how to launch, run, and grow a non-profit business, taking it to new heights with grant funding opportunities.
					</Text>
				</Box>
			</Box>
			<Box min-width="100px" min-height="100px" display="grid" justify-items="center">
				<Link
					text-decoration-line="initial"
					sm-text-align="center"
					border-width="2px"
					sm-margin="0px 0px 0px 0px"
					background="--color-darkL2"
					color="--lightD2"
					transition="background-color 0.3s linear 0s"
					hover-color="--light"
					href="#contact-us"
					padding="10px 23px 10px 23px"
					hover-background="--color-dark"
					font="normal 400 28px/1.5 --fontFamily-googleFiraSans"
					margin="60px 0px 0px 0px"
					sm-width="auto"
					border-color="--color-darkL2"
					sm-align-self="center"
					sm-display="inline-block"
				>
					Let's Talk
				</Link>
			</Box>
		</Section>
		<Section
			padding="88px 0 88px 0"
			sm-padding="60px 0 60px 0"
			box-shadow="--m"
			background="radial-gradient(circle at center,rgba(255, 255, 255, 0.67) 0%,--color-lightD2 61%) fixed,url(https://images.unsplash.com/photo-1593672755342-741a7f868732?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000),--color-light"
			md-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-25"
		>
			<Override slot="SectionContent" max-width="1220px" sm-min-width="280px" />
			<Box
				width="100%"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				margin="0px 0px 64px 0px"
				display="flex"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 28px/1.2 --fontFamily-sans"
					text-align="center"
					md-font="--headline3"
				>
					Let's Get You Funded
				</Text>
				<Text
					color="--dark"
					text-align="center"
					lg-width="100%"
					margin="0px 0px 0px 0px"
					font="--base"
					width="70%"
					lg-max-width="720px"
				>
					Discover new funding possibilities with The Fixit Queen. Learn how to leverage grants and innovative strategies to fuel your passion, dreams, and business.
				</Text>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="24px"
				lg-grid-gap="16px"
				md-grid-template-columns="1fr"
				align-self="auto"
			>
				<Box
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 46px 47px 46px"
					background="--color-light"
					display="flex"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Icon
						icon={GiTakeMyMoney}
						margin="0px 0px 30px 0px"
						color="--darkL2"
						size="64px"
						category="gi"
						lg-font="48px sans-serif"
					/>
					<Text color="--darkL2" font="--lead" margin="0px 0px 18px 0px" text-align="center">
						<Span
							letter-spacing="0.5px"
							text-transform="uppercase"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								color="--darkL2"
							>
								Grant Funding
							</Strong>
						</Span>
					</Text>
					<Text
						font="--base"
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--dark"
						text-align="center"
					>
						Fuel your passion and dreams with grant funding opportunities. Our expert grant writing services will position you for successful funding applications.
					</Text>
				</Box>
				<Box
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 46px 47px 46px"
					background="--color-light"
					display="flex"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
					color="--darkL2"
				>
					<Icon
						icon={GiReceiveMoney}
						margin="0px 0px 30px 0px"
						color="--darkL2"
						size="64px"
						category="gi"
						lg-font="48px sans-serif"
					/>
					<Text color="--darkL2" font="--lead" margin="0px 0px 18px 0px" text-align="center">
						<Strong
							letter-spacing="0.5px"
							text-transform="uppercase"
							text-align="center"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							color="--darkL2"
						>
							Creative FUnding Strategies
						</Strong>
					</Text>
					<Text
						font="--base"
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--dark"
						text-align="center"
					>
						Explore innovative ways to fund your projects and business. Our creative funding strategies are designed to maximize your financial potential.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section background="linear-gradient(0deg,rgba(255, 251, 251, 0.78) 0%,#ffffff 100%),url(https://images.unsplash.com/photo-1590508977829-08e993d27ede?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000)" color="--dark" padding="64px 0 64px 0" id="contact-us">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" lg-width="100%">
					<Box>
						<Text
							font="--base"
							color="--dark"
							letter-spacing="1px"
							text-transform="uppercase"
							margin="0"
						>
							Get In Touch
						</Text>
						<Text font="--headline3" max-width="500px" margin="10px 0 0 0" color="--darkL2">
							Contact us to get started today!
						</Text>
					</Box>
				</Box>
				<Box width="50%" padding="8px 8px 8px 8px" lg-width="100%">
					<Box>
						<Formspree endpoint="xyyqkedp" completeText="Message Sent Successfully!" font="18px --fontFamily-sansTrebuchet">
							<Box
								gap="16px"
								display="grid"
								flex-direction="row"
								flex-wrap="wrap"
								grid-template-columns="repeat(2, 1fr)"
								grid-gap="16px"
							>
								<Box sm-width="100%" display="flex" flex-direction="column">
									<Text font="--base" margin="0 0 4px 0">
										Full Name
									</Text>
									<Input
										width="100%"
										name="name"
										type="text"
										border-color="--color-darkL2"
										required
									/>
								</Box>
								<Box sm-width="100%" display="flex" flex-direction="column">
									<Text font="--base" margin="0 0 4px 0">
										Email
									</Text>
									<Input
										width="100%"
										type="email"
										name="email"
										border-color="--color-darkL2"
										required
									/>
								</Box>
								<Box sm-width="100%" display="flex" flex-direction="column">
									<Text font="--base" margin="0 0 4px 0">
										Phone Number
									</Text>
									<Input
										width="100%"
										type="text"
										name="phone"
										border-color="--color-darkL2"
										required
									/>
								</Box>
								<Box sm-width="100%" display="flex" flex-direction="column">
									<Text font="--base" margin="0 0 4px 0">
										Company Name
									</Text>
									<Input width="100%" type="text" name="company_name" border-color="--color-darkL2" />
								</Box>
								<Box sm-width="100%" display="flex" flex-direction="column">
									<Text font="--base" margin="0 0 4px 0">
										Company Address
									</Text>
									<Input width="100%" type="text" name="company_address" border-color="--color-darkL2" />
								</Box>
								<Box display="flex" flex-direction="column" grid-column="1 / span 2">
									<Text font="--base" margin="0 0 4px 0">
										What do you need help with?
									</Text>
									<Input
										as="textarea"
										rows="4"
										width="100%"
										name="message"
										border-color="--color-darkL2"
										required
									/>
								</Box>
								<Box display="flex" flex-direction="column" align-items="flex-start" grid-column="1 / span 2">
									<Button background="--color-darkL2">
										Send
									</Button>
								</Box>
							</Box>
						</Formspree>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section background="--color-dark" padding="60px 0" sm-padding="40px 0">
			<SocialMedia facebook="https://www.facebook.com/quarklyapp/">
				<Override
					slot="link"
					border-radius="50%"
					color="--grey"
					hover-color="--light"
					background="transparent"
					hover-background="transparent"
					margin="0 8px"
					href="https://www.facebook.com/FIXITQUEEN/"
				/>
			</SocialMedia>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6143a05129a0a1001e6c835a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n* {\n    scroll-behavior: smooth;\n    }"}
			</style>
		</RawHtml>
	</Theme>;
});